import { createApp } from 'vue'
import App from './App.vue'

import 'animate.css/animate.min.css'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'boxicons/css/boxicons.min.css'
import 'swiper/swiper-bundle.min.css'
import './assets/css/style.css'

import '@srexi/purecounterjs/js/purecounter_vanilla'
import 'aos/dist/aos'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'isotope-layout/dist/isotope.pkgd.min'
import 'swiper/swiper-bundle.esm.js'


createApp(App).mount('#app')
