<template>
    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
    <div class="container" data-aos="fade-up">

        <div class="section-title">
        <h2>Services</h2>
        </div>

        <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
            <div class="icon"><i class="bi bi-chat-left-dots"></i></div>
            <h4 class="title"><a href="">公有雲服務</a></h4>
            <p class="description">公有雲是一種雲端部署模式，由公有雲服務供應商提供雲端資源基礎架構（包含運算、存儲、網路、加密、應用程式…等），讓消費者透過網際網路租用。消費者可專注在公有雲上架設網站、儲存資料、分析數據、開發應用程式…等，將資料中心設備、機房、網路基礎架構的建置、管理和維運成本則由公有雲服務供應商負責。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="bi bi-bounding-box"></i></div>
            <h4 class="title"><a href="">新世代託管服務</a></h4>
            <p class="description">雲端技術在許多方面重塑傳統 IT 模式，包含： IT 架構、技術發展，甚至是企業文化，同時，雲端趨勢也創造出一種新型 IT 服務需求稱為「新世代託管服務」。新世代託管服務宗旨是協助客戶降低營運費用與風險、同時增加效率。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="bi bi-globe"></i></div>
            <h4 class="title"><a href="">網站加速</a></h4>
            <p class="description">網站流量激增時，用戶請求量、下行流量頻寬增高，伺服器壓力大，站點回應慢。CDN通過全球2800+的節點及智能調度系統，讓用戶可以就近訪問海量的靜態資源。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="bi bi-broadcast"></i></div>
            <h4 class="title"><a href="">下載加速</a></h4>
            <p class="description">支持各類文件的下載、發送、及線上播放加速等業務(如MP4、FLV影片檔或者平均單個大小在20M以上的文件)。搭配OSS接入CDN加速，節約近2/3回源頻寬成本。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
            <div class="icon"><i class="bi bi-brightness-high"></i></div>
            <h4 class="title"><a href="">直播加速</a></h4>
            <p class="description">基於領先的內容導入與網路發布和大規模分布式即時轉檔技術，打造全面性的影音直播平台，提供媒體儲存、切片轉檔、訪問鑒權、內容傳輸加速CDN/DCDN等一體化解決方案。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
            <div class="icon"><i class="bi bi-calendar2-week"></i></div>
            <h4 class="title"><a href="">行動加速</a></h4>
            <p class="description">行動APP更新文件(apk文件)發送，APP內圖片、網頁、短影音、UGC等內容進行加速發送。提供HTTPDNS服務，避免DNS劫持並進行精準解析。</p>
        </div>
        </div>

    </div>
    </section><!-- End Services Section -->
</template>
<script>
export default {
  name: 'ServiceComponent',
}
</script>