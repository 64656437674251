<template>
  <TopBarComponent :contactInfo=contactInfo />
  <HeaderComponent :contactInfo=contactInfo />
  <HeroComponent/>
  <main id="main">
    <AboutComponent/>
    <AboutListsComponent/>
    <CountComponent/>
    <ServiceComponent/>
    <PortfolioComponent/>
    <TeamComponent/>
    <FrequentlyAskedComponent/>
    <ContactUsComponent/>
  </main><!-- End #main -->
  <FooterComponent/>
</template>

<script>
import TopBarComponent from '@/components/TopBarComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import HeroComponent from '@/components/HeroComponent.vue';
import AboutComponent from '@/components/AboutComponent.vue';
import AboutListsComponent from '@/components/AboutListsComponent.vue';
import CountComponent from '@/components/CountComponent.vue';
import ServiceComponent from '@/components/ServiceComponent.vue';
import PortfolioComponent  from '@/components/PortfolioComponent.vue';
import TeamComponent from '@/components/TeamComponent.vue';
import FrequentlyAskedComponent from '@/components/FrequentlyAskedComponent.vue';
import ContactUsComponent from '@/components/ContactUsComponent.vue';

import axios from 'axios';
const url = '/contactInfo.json';

export default {
  name: 'HomePage',
  data () {
    return {
      contactInfo: Object
    }
  },
  mounted(){
    axios.get(url)
    .then((res)=>{
      this.contactInfo = res.data;
    })
  },
  components: {
    TopBarComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    AboutComponent,
    AboutListsComponent,
    CountComponent,
    ServiceComponent,
    PortfolioComponent,
    TeamComponent,
    FrequentlyAskedComponent,
    ContactUsComponent
  }
}
</script>