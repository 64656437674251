<template>
    <!-- ======= Top Bar ======= -->
    <section id="topbar" class="d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope-fill"></i><a :href="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a>
          <i class="bi bi-phone-fill phone-icon"></i> {{ contactInfo.phone }}
        </div>
        <div class="social-links d-none d-md-block">
          <a :href="'//' + contactInfo.twitter" class="twitter"><i class="bi bi-twitter"></i></a>
          <a :href="'//' + contactInfo.facebook" class="facebook"><i class="bi bi-facebook"></i></a>
          <a :href="'//' + contactInfo.instagram" class="instagram"><i class="bi bi-instagram"></i></a>
          <a :href="'//' + contactInfo.linkedin" class="linkedin"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </section>
</template>>

<script>

export default {
  name: 'TopBarComponent',
  props: {
    contactInfo: Object
  }
}
</script>