<template>
    <!-- ======= About Lists Section ======= -->
    <section class="about-lists">
    <div class="container">

        <div class="row no-gutters">

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up">
            <img :src="require('../assets/img/service/aws_partner.png')" class="img-fluid" alt="">
            <h4>亞馬遜雲夥伴</h4>
            <p>知名公有雲平台全球合作夥伴，致力於AWS 原生服務，專注在於客戶資料搬遷以及客戶平臺架構優化。</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="100">
            <img :src="require('../assets/img/service/aws_certification.png')" class="img-fluid" alt="">
            <h4>專業證照</h4>
            <p>擁有多數張專業技術認證，具有AWS的SAA,SAP 和Specialty認證工程團隊，在「機器學習」、「基礎架構」、「資料分析」及「雲端遷移」領域都有專業認證以及講師認證。</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="200">
            <img :src="require('../assets/img/service/24x7.png')" class="img-fluid" alt="">
            <h4>24/7 技術服務</h4>
            <p>24小時365天全年無休，即時監控客戶所有雲端產品，替客戶部署最強大且最可靠的數位生態系。</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="300">
            <img :src="require('../assets/img/service/multi_solution.png')" class="img-fluid" alt="">
            <h4>各種解決方案</h4>
            <p>超過30種適用於各行各業的數位轉型解決方案，更可依照需求客製打造，提供客戶「免費測試」及「免費試用」等貼心服務。</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="400">
            <img :src="require('../assets/img/service/global_service.png')" class="img-fluid" alt="">
            <h4>多地區服務</h4>
            <p>多元地區在地化服務，包括總部新加坡，分公司柬埔寨及台灣</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="500">
            <span>06</span>
            <h4>Facilis Impedit</h4>
            <p>Quis eum numquam veniam ea voluptatibus voluptas. Excepturi aut nostrum repudiandae voluptatibus corporis sequi</p>
        </div>

        </div>

    </div>
    </section><!-- End About Lists Section -->
</template>
<script>
export default {
  name: 'AboutListsComponent',
}
</script>