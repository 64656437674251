<template>
    <!-- ======= Our Team Section ======= -->
    <section id="team" class="team">
    <div class="container" data-aos="fade-up">

        <div class="section-title">
        <h2>Our Team</h2>
        <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.</p>
        </div>

        <div class="row">

        <div class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
            <div class="member">
            <div class="pic"><img :src="require('../assets/img/team/team-1.jpg')" class="img-fluid" alt=""/></div>
            <div class="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
            </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="member">
            <div class="pic"><img :src="require('../assets/img/team/team-2.jpg')" class="img-fluid" alt=""/></div>
            <div class="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
                <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
            </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
            <div class="pic"><img :src="require('../assets/img/team/team-3.jpg')" class="img-fluid" alt=""/></div>
            <div class="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
                <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
            </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
            <div class="pic"><img :src="require('../assets/img/team/team-4.jpg')" class="img-fluid" alt=""/></div>
            <div class="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
                <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
            </div>
            </div>
        </div>

        </div>

    </div>
    </section><!-- End Our Team Section -->
</template>
<script>
export default {
  name: 'TeamComponent',
}
</script>